import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import UpdateLawDocs from '../../../components/updateLawDocs'
import './style.scss'
const UpdateLawDocsPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           <UpdateLawDocs
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default UpdateLawDocsPage
/* UpdateLawDocs */