import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link,withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./switch.css";
/* import "./style.scss"; */
import SignatureCanvas from "react-signature-canvas";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Header, Feed } from 'semantic-ui-react'

import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { EditorState, convertToRaw,convertFromRaw,ContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const recaptchaRef = React.createRef();
class AddLawDocsContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      description: "",
      error: {},
      show: false,
      loaderFlag:false,
      editorState: EditorState.createEmpty(),
      errMessage:'',
      AddLawDocsFlag:false
    };
   
  }
  handleClose=()=> {
    this.setState({ show: false });
    this.props.history.push('/allLawDocuments')
  }

  handleShow=()=> {
    this.setState({ show: true });
  }

  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Document Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
     
    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Summary Of Document can not be Empty ";
              break;
          }
        });
        return errors;
      }),
   
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      description: this.state.description,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      
      let data=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
      if(data.length>100){
    this.setState({AddLawDocsFlag:true,loaderFlag:true,errMessage:''})

             /*createLawDocsSubmission*/  
           /* createLawRed 
           trimmedDataURL
           */
             let  obj={
              name: this.state.fname,
              reference: this.reference.value,
              description: this.state.description,
              writting: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
          }
             /* this.state.cardType */
             this.props.dispatch(Actions.createLawDocsSubmission(obj))
        

            } else{
this.setState({errMessage:'Few Detail must be requied in the Document Writing'})
            }
          }     
          
          
        }
  
 
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.createLawRed;    
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
          if(this.state.AddLawDocsFlag){
       console.log(resNumber1.data,"file submission area")
            this.setState({loaderFlag:false,show:true})
           
          }
      } else {
      this.setState({loaderFlag:false,errMessage:resNumber1.errMsg})
      }
    }

  }  

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
  render() {
    const { editorState } = this.state;
    return (
      <div className="admin-cutomer-profile-area" >
      <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'100px'}}>
      <div style={{padding:'20px',paddingLeft:'20px',paddingRight:'20px'}}>
   
<div className="visitor-form-border-box1">
        <div className="visitor-form-border-box-left1">
m
</div>
<div className="visitor-form-border-box-right1">
j
</div>
        </div>
        <form onSubmit={this.submitHandler} className="counsellingForm">
        
          <div className="caseFiletopAreaHeaderREceipnest">
            <h1 style={{fontWeight:'550'}}>Add Law Document <span className="arabic-style-font-visitor" style={{marginLeft:'20px'}}>إضافة وثيقة قانون</span></h1>
            <img src={require("../../images/logo/logo55.png")} />
          </div>
          <div className="caseFiletopAreaHeaderb">
            <p>
              {" "}
              <p >
                Date{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getDate() + 1 > 9 ? "" : 0}
                  {new Date().getDate()}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getMonth() + 1 > 9 ? "" : 0}
                  {new Date().getMonth() + 1}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getFullYear()}
                </span>{" "}
              </p>
            </p>
            <div className="file-no-area" >
              <p style={{fontSize:'15px'}}></p>
            </div>
          </div>
          <div className="visitor-form-border-box">
        <div className="visitor-form-border-box-left">
m
</div>
<div className="visitor-form-border-box-right">
j
</div>
        </div>
         
          <div className="row" style={{marginTop:'20px'}}>
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                    Doument Name*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
اسم الملف
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  onChange={this.changeHandler}
                  name="fname"
                  className="form-control"
                  type="text"
                />

                {this.state.error.fname && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.fname}
                  </p>
                )}
              </div>
            </div>
           
            <div className="col-12 col-md-6">
              <div className="formInput">
               
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Document Reference
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
وثيقة مرجعية
                </span>
                </div>
                <input
                  placeholder=""
                 ref={(input=>this.reference=input)}
                  className="form-control"
                  type="text"
                  dir={this.state.typingArabic?"rtl":"ltr"}
                />

              </div>
            </div>

          
            <div className="col-12">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
   Short Description of Doument*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
وصف قصير للدواء
               </span>
                </div>
                <textarea
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  className="form-control"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  placeholder="Type your case detail*"
                  name="description"
                  row="1"
                />
                {this.state.error.description && (
                  <p>{this.state.error.description}</p>
                )}
              </div>
            </div>
           
           
            <div className="col-12" style={{ marginTop: "0px" }}>
            <div className="case-writing-header">
                      <p style={{color:'#282e2f',fontWeight:'550'}}>Document writing</p>
                      <p style={{fontSize:'30px',fontWeight:'700'}} className="arabic-font">كتابة الوثيقة</p>
                    </div>
                    <div className="case-writing-editor-area">
            <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        </div>
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <p style={{color:'red',fontSize:'20px',fontWeight:'550'}}>{this.state.errMessage}</p>
                </div>
           
            {/* errMessage */}
         
{/*    */}
           
            <div className="col-12" style={{ marginTop: "20px" }}>
              
              <div className="signon-bottom-area1">
             {/*  <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       /> */}
                 <div></div>      
            <div className="customer-file-loader-with-button" >  <span style={{width:'50px',height:'50px'}}>{this.state.loaderFlag?<div style={{marginRight:'10px'}} className="loader"></div> :""}</span>  <button style={{display:'inline'}} disabled={this.state.buttonFlag}  type="submit">submit</button>
             </div>
             </div>
            </div>
          </div>
          <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            >
         <img style={{width:'30px'}} src={require('../../images/about/man-01.png')} />  Document Messsage
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
               <center>
                   <p><i style={{fontSize:'120px'}} class="fa fa fa-check-circle-o"></i></p>
                   <h2>thank you!</h2>
                   <p>Document has been successfully uploaded!</p>
                   <button className="sent-button-des" ><Link to="/allLawDocuments"  style={{color:'white'}} >OK</Link></button>
               </center>
              </div>
           
          </Modal.Body>
      
        </Modal>
        </form>
  </div>
  </div>
       </div>
       </div>
    );
  }
}
const mapStateToProps=(state)=> {
  return {
    createLawRed: state.AddLawDocsContent1.createLawRed,


  };
}
export default compose(
  withReducer("AddLawDocsContent1", reducer),
  connect(mapStateToProps),withRouter
)(AddLawDocsContent); 