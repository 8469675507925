import axios from "axios";
import setAuthToken from '../../setAuthToken';
import jwt_decode from 'jwt-decode';
import {setCurrentAdmin} from './codeVerifyAuth'
//GET ALL CARCATAGORY BY COUNTRY
export const ADMIN_SIGN_IN = "ADMIN_SIGN_IN";
export const ADMIN_SIGN_IN_LOADING =
"ADMIN_SIGN_IN_LOADING";
export const ADMIN_SIGN_IN_LOADING_FAILED =
"ADMIN_SIGN_IN_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const adminSignInLoading = ms => ({
    type: ADMIN_SIGN_IN_LOADING,
    payload: ms
    });
    
    export const adminSignInLoadingFailed = ms => ({
    type: ADMIN_SIGN_IN_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function adminSignIn(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/profileService/api/v1/legalPenal/auth/legalPenalLogin`,obj);
    return dispatch => {

    dispatch(adminSignInLoading());
    request
    .then(response => {    
    if (response.status == 200) {
        if(response.data.user.emailVerified === true){
            let  token  = response.data.user.token;
            localStorage.setItem('consultantToken', token);
            localStorage.setItem('receptionist',response.data.user.firstName);
            localStorage.setItem('loginFlagConsultant','login')
            localStorage.setItem('consultantreName',response.data.user.firstName+" "+response.data.user.lastName);
            localStorage.setItem('consultantreEmail',response.data.user.email);
            localStorage.setItem('consultantrePhone',response.data.user.phoneNumber);
            
            localStorage.setItem('consultantBar',"casesConsultant");
            localStorage.setItem('consultantLink',"AllSasesConsultant");
         localStorage.setItem('defaultSelectedKeys','AllSasesConsultant')
            localStorage.setItem('openKeys','casesConsultant')

            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentAdmin(response.data.user.firstName));
            dispatch({
                type: ADMIN_SIGN_IN,
                payload: response.data
                });
        }
  
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        adminSignInLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(adminSignInLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(adminSignInLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(adminSignInLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


