import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const FILES_SEARCH_WITH_NUMBER_NAME = "FILES_SEARCH_WITH_NUMBER_NAME";
export const FILES_SEARCH_WITH_NUMBER_NAME_LOADING =
"FILES_SEARCH_WITH_NUMBER_NAME_LOADING";
export const FILES_SEARCH_WITH_NUMBER_NAME_LOADING_FAILED =
"FILES_SEARCH_WITH_NUMBER_NAME_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getSeachedFilesLoading = ms => ({
    type: FILES_SEARCH_WITH_NUMBER_NAME_LOADING,
    payload: ms
    });
    
    export const getSeachedFilesLoadingFailed = ms => ({
    type: FILES_SEARCH_WITH_NUMBER_NAME_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getSeachedFiles(value) {
        const request = axios.get(
            `https://prod-legalemirates.wowets.com/profileService/api/v1/legalPenal/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
    
    
    return dispatch => {

    dispatch(getSeachedFilesLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
   /*   console.log(response,"response getSeachedFiles") */
    dispatch({
    type: FILES_SEARCH_WITH_NUMBER_NAME,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getSeachedFilesLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getSeachedFilesLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getSeachedFilesLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getSeachedFilesLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


