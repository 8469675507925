import {combineReducers} from 'redux';

import {addDocumentREd} from './aadDocRed'

import {fileSearchReducer} from './fileSearch'
import {getCaseDetailReducer } from './getCAseDetailRed';
import {updateCaseWritingReducer} from './updateCaseWriting'
import {addAudioNoteREd} from './addAudioNoteRed'
const reducer = combineReducers({
   getCaseDetailReducer,fileSearchReducer,addDocumentREd,updateCaseWritingReducer ,addAudioNoteREd
    });
    
    export default reducer;
