import React, { useEffect, useState, Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import "../sideBar/style.scss";
import "../sideBar/style.css";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import {
  Form,
  ButtonToolbar,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
} from "react-bootstrap";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from "antd";
import "antd/dist/antd.css";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Rating, Icon } from "semantic-ui-react";
var moment = require("moment");
class AllCases extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      limit: 10,
      pageNo: 1,
      userId: undefined,
      totalPages: 1,
      casesfilesData: [],
      pageNumber: 1,
      options: [],
      direction: null,
      column: null,
      stats:{}
    };
  }
  componentWillMount() {
    let obj = {
      userId: this.state.userId,
      limit: this.state.limit,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllCustomerCases(obj));
    this.props.dispatch(Actions.getSeachedFiles(""));
    this.props.dispatch(Actions.getAllCustomerCasesCounter());
    /* getAllCustomerCasesCounter */
  }
  handleLimit = (e) => {
    this.setState({ limit: e.target.value, casesfilesData: [] });
    let obj = {
      userId: this.state.userId,
      limit: e.target.value,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllCustomerCases(obj));
  };
  handleJumpPage = (e) => {
    this.setState({ pageNumber: e.target.value, casesfilesData: [] });
    let obj = {
      userId: this.state.userId,
      limit: this.state.limit,
      pageNo: e.target.value,
    };
    this.props.dispatch(Actions.getAllCustomerCases(obj));
  };
  handlePageInc = () => {
    if (this.state.totalPages > this.state.pageNumber ) {
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber + 1,
      }));
      this.setState({ casesfilesData: [] });
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNumber + 1,
      };
      this.props.dispatch(Actions.getAllCustomerCases(obj));
    }
  };
  handlePageDec = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ casesfilesData: [] });
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber - 1,
      }));
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNumber - 1,
      };
      this.props.dispatch(Actions.getAllCustomerCases(obj));
    }
  };
  /* allCasesReducer */
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.allCasesReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
        if (resNumber1.data.cases.docs.length > 0) {
          this.setState({
            casesfilesData: resNumber1.data.cases.docs,
            totalPages: resNumber1.data.cases.totalPages,
          });
        } else {
          this.setState({ casesfilesData: ["nothing"] });
        }
      } else {
        this.setState({
          loaderFlag: false,
          errFlag: true,
          errMessage: resNumber1.errMsg,
        });
      }
    }
    let resNumber2 = nextProps.fileSearchReducer;

    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data === undefined)) {
        if (resNumber2.data.users.length > 0) {
          resNumber2.data.users.map((item) => {
            let obj = {
              value: `${item.firstName} ${item.lastName} (${item.phoneNumber})`,
              data: item,
            };
            this.state.options.push(obj);
          });
          this.setState({ falged: true });
        }
      } else {
        if (resNumber2.errMsg === "This token is expire! Please log in again") {
          this.props.dispatch(Actions.logoutAdmin());
        }
      }
    }
    let resNumber4 = nextProps.allCasesCounterReducer;

    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
        this.setState({stats:resNumber4.data.stats})
      } else {
       
      }
    }
    /* allCasesCounterReducer */
  }
  /* fileSearchReducer */
  handleSearch = (e) => {
    if (e.length > 0) {
      let uniq = {};
      let arrFiltered = this.state.options.filter(
        (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
      );
      this.setState({ options: arrFiltered });
      this.props.dispatch(Actions.getSeachedFiles(e));
    } else {
      this.setState({ casesfilesData: [] });
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNo,
      };
      this.props.dispatch(Actions.getAllCustomerCases(obj));
    }
  };
  handleSelect = (e, value) => {
    let uniq = {};
    let arrFiltered = this.state.options.filter(
      (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
    );
    this.setState({ options: arrFiltered, casesfilesData: [] });
    let obj = {
      userId: value.data._id,
      limit: this.state.limit,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllCustomerCases(obj));
  };
  handleSort = (action) => {
    switch (action.type) {
      case "CHANGE_SORT":
        this.setState({ column: action.column });
        this.setState((state) => ({
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        }));
        this.setState((state) => ({
          casesfilesData: state.casesfilesData.reverse(),
        }));
      default:
    }
  };
  handleCaseDetail=(item)=>{
 localStorage.setItem('caseId',item._id)   
this.props.history.push('/caseDetail')
  }
  render() {
    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "200px" }}
          >
            <div className="case-overview-items-area">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="case-overview-items-receptionist">
                    <div className="case-overview-items-receptionist-left">
                      <img src={require("../../images/cases/1.png")} />
                    </div>
                    <div className="case-overview-items-receptionist-right">
                      <p>Total Cases</p>
                      <center>
                       {/*  {console.log(this.state.stats,"ffffffffffff")} */}
    <span>{this.state.stats.ALL && this.state.stats.ALL }</span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="case-overview-items-receptionist">
                    <div className="case-overview-items-receptionist-left">
                      <img src={require("../../images/cases/2.png")} />
                    </div>
                    <div className="case-overview-items-receptionist-right">
                      <p>Active Cases</p>
                      <center>
                        <span>{this.state.stats.ACTIVE && this.state.stats.ACTIVE }</span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="case-overview-items-receptionist">
                    <div className="case-overview-items-receptionist-left">
                      <img src={require("../../images/cases/3.png")} />
                    </div>
                    <div className="case-overview-items-receptionist-right">
                      <p>Pending Cases</p>
                      <center>
                        <span>{this.state.stats.PENDING && this.state.stats.PENDING }</span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="case-overview-items-receptionist">
                    <div className="case-overview-items-receptionist-left">
                      <img src={require("../../images/cases/4.png")} />
                    </div>
                    <div className="case-overview-items-receptionist-right">
                      <p>Closed Cases</p>
                      <center>
                        <span>{this.state.stats.CLOSED && this.state.stats.CLOSED }</span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-cutomer-REcepnest-files-rea">
              <div className="receipnest-file-customer-files-area-top-heading">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "550",
                    marginTop: "25px",
                  }}
                >
                  {" "}
                  Cases List
                </p>
              </div>
              <div className="admin-cutomer-profile-area-search">
                <div className="search-area-admin-top">
                  <i className="fa fa-search"></i> |{" "}
                  {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */}{" "}
                  <AutoComplete
                    className="search_input_width_file_rep"
                    options={this.state.options}
                    onSearch={(e) => this.handleSearch(e)}
                    onSelect={(e, value) => this.handleSelect(e, value)}
                    placeholder="search with Name or Phone No"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="receipnest-file-customer-files-area655-bottom-area"
              style={{ overflow: "auto", display: "block" }}
            >
              <div className="session-table-area-receptionist">
                <Table celled fixed padded selectable sortable>
                  <Table.Header style={{ fontSize: "12px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "Status"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "Status",
                          })
                        }
                        textAlign="left"
                        singleLine
                      >
                        Status
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "CaseNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "CaseNo",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Case No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "FileNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "FileNo",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        File No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "CaseType"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "CaseType",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Case Type
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "created"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "created",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Stage
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "created"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "created",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "created"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "created",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Email
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        sorted={
                          this.state.column === "created"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "created",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Contact
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "created"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "created",
                          })
                        }
                        singleLine
                        textAlign="center"
                      >
                        Emirates ID
                      </Table.HeaderCell>
                      <Table.HeaderCell singleLine textAlign="center">
                        Info
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body style={{ fontSize: "12px", fontWeight: "550" }}>
                    {this.state.casesfilesData.length > 0 ? (
                      this.state.casesfilesData[0] != "nothing" ? (
                        this.state.casesfilesData.map((item) => {
                          return <Table.Row>
                            {item.caseStatus==="PENDING"? <Table.Cell textAlign="center" warning>
                        <div style={{color:'#ff0000'}}><Icon name="attention" /> {item.caseStatus}{" "}</div>
                            </Table.Cell>:
                             <Table.Cell textAlign="center" warning>
                             <Icon name="check circle outlin" /> {item.caseStatus}{" "}
                           </Table.Cell>
                            }
                           
                          <Table.Cell textAlign="center"> {item.caseNo}</Table.Cell>
                            <Table.Cell textAlign="center"> BZ/{item.fileNo} </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                          {item.caseType}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                          {item.stage}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                          {item.userProfile&& item.userProfile.firstName+" "+item.userProfile.lastName}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                              {item.userProfile&& item.userProfile.phoneNumber}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                              {item.userProfile&& item.userProfile.email}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {" "}
                              {item.emiratesId?item.emiratesId:item.emiratesIdNumber}{" "}
                            </Table.Cell>
                            <Table.Cell textAlign="center"> <Icon onClick={()=>this.handleCaseDetail(item)} name="attention" size="large" style={{cursor:'pointer'}}  title="Case Detail" /> </Table.Cell>
                          </Table.Row>;
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan="10" textAlign="center">
                            <center>
                              <div
                                className="loader-for-tabel-invoce"
                                style={{ fontWeight: "550" }}
                              >
                                No Case  Exist Yet!
                              </div>
                            </center>
                          </Table.Cell>
                        </Table.Row>
                      )
                    ) : (
                      <Table.Row>
                        <Table.Cell
                          textAlign="center"
                          colSpan="10"
                          textAlign="center"
                        >
                          <center>
                            <div className="loader-for-tabel-invoce">
                              <div className="loader1188"></div>
                            </div>
                          </center>
                        </Table.Cell>
                      </Table.Row>
                    )}
                   </Table.Body> 
                </Table>
              </div>
            </div>
            <div className="quotaion-table-pagination-area">
              <div style={{ marginTop: "10px" }}>
                show{" "}
                <select onChange={this.handleLimit}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>{" "}
                per page
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ marginRight: "15px" }}>
                  Page {this.state.pageNumber} of {this.state.totalPages}{" "}
                  <span className="pagination-table-quotaion-dec">
                    <i
                      title="Previous Page"
                      onClick={this.handlePageDec}
                      className="fa fa-caret-left"
                    ></i>
                  </span>
                  <span className="pagination-table-quotaion-inc">
                    <i
                      onClick={this.handlePageInc}
                      title="Next Page"
                      className="fa fa-caret-right"
                    ></i>
                  </span>
                </span>
                jump to page{" "}
                <select onChange={this.handleJumpPage}>
                  {Array.from(
                    { length: this.state.totalPages },
                    (x, i) => i
                  ).map((item, index) => {
                    return <option value={index + 1}>{index + 1}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allCasesReducer: state.AllCases1.allCasesReducer,
    fileSearchReducer: state.AllCases1.fileSearchReducer,
    allCasesCounterReducer:state.AllCases1.allCasesCounterReducer
  };
};
export default compose(
  withReducer("AllCases1", reducer),
  connect(mapStateToProps),
  withRouter
)(AllCases);
