import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_ALL_LAW_DOCS = "GET_ALL_LAW_DOCS";
export const GET_ALL_LAW_DOCS_LOADING =
"GET_ALL_LAW_DOCS_LOADING";
export const GET_ALL_LAW_DOCS_LOADING_FAILED =
"GET_ALL_LAW_DOCS_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllLawDocsLoading = ms => ({
    type: GET_ALL_LAW_DOCS_LOADING,
    payload: ms
    });
    
    export const getAllLawDocsLoadingFailed = ms => ({
    type: GET_ALL_LAW_DOCS_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllLawDocs(obj) {
    if(obj.userId===undefined){
        var request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/legalPenalRoutes/api/v1/lawDocuments/getLawDocumentsWithPagination?pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
        
    }else{
        var request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/legalPenalRoutes/api/v1/lawDocuments/getLawDocumentsWithPagination?name=${obj.name}&pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
    }
    
    return dispatch => {

    dispatch(getAllLawDocsLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getAllLawDocs")
    dispatch({
    type: GET_ALL_LAW_DOCS,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllLawDocsLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllLawDocsLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllLawDocsLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllLawDocsLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


