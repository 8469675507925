import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const ADD_LAW_DOCS = "ADD_LAW_DOCS";
export const ADD_LAW_DOCS_LOADING =
"ADD_LAW_DOCS_LOADING";
export const ADD_LAW_DOCS_LOADING_FAILED =
"ADD_LAW_DOCS_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const createLawDocsSubmissionLoading = ms => ({
    type: ADD_LAW_DOCS_LOADING,
    payload: ms
    });
    
    export const createLawDocsSubmissionLoadingFailed = ms => ({
    type: ADD_LAW_DOCS_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function createLawDocsSubmission(obj) {
    const request = axios.post(
    `https://prod-legalemirates.wowets.com/fileService/legalPenalRoutes/api/v1/lawDocuments/createLawDocument`,obj, {
        headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
      });
    return dispatch => {

    dispatch(createLawDocsSubmissionLoading());
    request
    .then(response => {   
  
    if (response.status == 201 || response.status == 200) {
     console.log(response,"response createLawDocsSubmission")
    dispatch({
    type: ADD_LAW_DOCS,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        createLawDocsSubmissionLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(createLawDocsSubmissionLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(createLawDocsSubmissionLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(createLawDocsSubmissionLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


