import React, { useEffect, useState, Component } from "react";

import "../sideBar/style.scss";
import "../sideBar/style.css";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import {
  Form,
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
} from "react-bootstrap";
import { NavLink, Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from "antd";
import "antd/dist/antd.css";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Rating, Icon,Button, Input,Label } from "semantic-ui-react";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Tabs,Alert } from "antd";
import LawDetailView from './lawDetailView'

var moment = require("moment");
const { TabPane } = Tabs;
class LawDocsDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      lawDetail:{}
      
    };
    this.ref = React.createRef();
  }
 
  componentWillMount() {
    /* getDocsLawDetail */
    this.props.dispatch(Actions.getDocsLawDetail(localStorage.lawDocumentId))
  }

  /* getLawDocsDetailReducer */
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.getLawDocsDetailReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
       /*  this.setState({caseDetail:resNumber1.data.caseDetail}) */
       this.setState({lawDetail:resNumber1.data.lawDocuments.docs[0]})
 
      } else {
      
      }
    }
  
  
    /* addDocumentREd */
  }


  render() {
    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "600px" }}
          >
             <center>
             <Button style={{marginTop:'30px',marginBottom:'20px'}}>   <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print this Document</div>;
                                      }}
                                      content={() => this.componentRef}
                                      documentTitle="Legal Emirates Case Writing"
                                    /></Button>
             </center>
             <LawDetailView data={this.state.lawDetail} ref={(el) => (this.componentRef = el)} />                        
           </div>
           </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getLawDocsDetailReducer: state.LawDocsDetails1.getLawDocsDetailReducer,
  };
};
export default compose(
  withReducer("LawDocsDetails1", reducer),
  connect(mapStateToProps),
  withRouter
)(LawDocsDetails);
