import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const ALL_CUSTOMER_CASES_RECEPTIONEST = "ALL_CUSTOMER_CASES_RECEPTIONEST";
export const ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING =
"ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING";
export const ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING_FAILED =
"ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllCustomerCasesLoading = ms => ({
    type: ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING,
    payload: ms
    });
    
    export const getAllCustomerCasesLoadingFailed = ms => ({
    type: ALL_CUSTOMER_CASES_RECEPTIONEST_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllCustomerCases(obj) {
    if(obj.userId===undefined){
        var request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/legalPenalRoutes/api/v1/cases/getCasesWithPagination?pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
        
    }else{
        var request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/receptionistRoutes/api/v1/cases/getCasesWithPagination?userId=${obj.userId}&pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
    }
    
    return dispatch => {

    dispatch(getAllCustomerCasesLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getAllCustomerCases")
    dispatch({
    type: ALL_CUSTOMER_CASES_RECEPTIONEST,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllCustomerCasesLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllCustomerCasesLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllCustomerCasesLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllCustomerCasesLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


