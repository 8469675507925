import React, { useEffect, useState, Component } from "react";

import "../sideBar/style.scss";
import "../sideBar/style.css";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import {
  Form,
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
} from "react-bootstrap";
import { NavLink, Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from "antd";
import { notification } from 'antd'
import "antd/dist/antd.css";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Rating, Icon,Button, Input,Label } from "semantic-ui-react";
import { Tabs,Alert } from "antd";
import CaseWriting from './caseWrting'
import AddAudioCase from './addCaseAdio'

var moment = require("moment");
const { TabPane } = Tabs;
class CaseDetail extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      limit: 10,
      pageNo: 1,
      userId: undefined,
      totalPages: 11,
      casesfilesData: [],
      pageNumber: 1,
      options: [],
      direction: null,
      column: null,
      stats: {},
      caseDetail:{},
      veiwReceiptLoading:false,
      veiwReceiptSuccess:false,
      veiwReceiptFlag:false,
      veiwReceiptErrorFlag:false,
      veiwReceiptError:'',
      DocsIMage:'',
      size:'sm',
      height:'150px',
      show5:false,
      addCaseDocsSuccess:false,
      addCaseDocsError:'',
      addCaseDocsFlag:false,
      addCaseDocsErrorFlag:false,
      loaderFlag3:false,
      preview:'',
      photo:'',
      
    };
  }
  handleShow=()=> {
    this.setState({ show: true });
  }
  handleClose=()=> {
    this.setState({ show: false,
      veiwReceiptLoading:false,veiwReceiptSuccess:true });
    }
    handleClose5=()=> {
      this.setState({ show5: false });
      setTimeout(()=>{
        this.setState({ preview:'',photo:'',loaderFlag3:false,addCaseDocsSuccess:false,addCaseDocsFlag:false,addCaseDocsErrorFlag:false,addCaseDocsError:'' });
    
      },500)
      this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
    }
    
    handleShow5=()=> {
      this.setState({ show5: true });
    }
  componentWillMount() {
    /* getCaseDetail */
    this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
  }

  /* getCaseDetailReducer */
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.getCaseDetailReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
        this.setState({caseDetail:resNumber1.data.caseDetail})
 
      } else {
      
      }
    }
    let resNumber7 = nextProps.addAudioNoteREd;

    if (!resNumber7.isLoading) {
      if (resNumber7.errMsg == null && !(resNumber7.data === undefined)) {
      /*   console.log(resNumber7.data.updatedCase,"gggggggggg") */
       /*  this.setState({caseDetail:resNumber1.data.updatedCase}) */
       
 
      } else {
      
      }
    }
  /* addAudioNoteREd */

    let resNumber9 = nextProps.addDocumentREd;

    if (!resNumber9.isLoading) {
      if (resNumber9.errMsg == null && !(resNumber9.data === undefined)) {
          if(this.state.addCaseDocsFlag){
    this.setState({loaderFlag3:false,addCaseDocsSuccess:true,addCaseDocsError:'',addCaseDocsFlag:false})
    
          }
      } else {
        if(this.state.addCaseDocsErrorFlag){
          this.setState({loaderFlag3:false,addCaseDocsSuccess:false,addCaseDocsErrorFlag:false,addCaseDocsError:resNumber9.errMsg})
          }
      }
    }
    /* addDocumentREd */
  }
  /* fileSearchReducer */
  handleShowDoc=(item)=>{
    this.setState({show:true,veiwReceiptLoading:true,veiwReceiptSuccess:false,height:'150px',size:'sm',DocsIMage:item.docLink})

 /*   this.props.dispatch(Actions.veiwReceiptImage(item.docLink)) */
    
  }
  handleLoad=()=>{
    this.setState({veiwReceiptLoading:false,veiwReceiptSuccess:true,size:'lg',height:'500px'})	
  }
  handleFileChange = e => {
     
    this.setState({
      photo: e.target.files[0],addCaseDocsError:''
    })
   
    var reader = new FileReader();
  var url = reader.readAsDataURL(e.target.files[0]);
  reader.onloadend =  (e)=> {
    this.setState({
        preview: [reader.result]
    })
  }
  }
  handleAddDocuments=()=>{
    if(this.documentName.value.length>0){
    if(!(this.state.photo==="")){
    let documentName=this.documentName.value;
    let documentNo=this.documentNo.value;
    this.setState({loaderFlag3:true,addCaseDocsFlag:true,addCaseDocsErrorFlag:true})
    let data=new FormData()
    data.append('docName',documentName)
    data.append('docNumber',documentNo)
    data.append('caseAttachment',this.state.photo)
    data.append('caseId',this.state.caseDetail._id)

    this.props.dispatch(Actions.addDocumentCase(data))
  
 setTimeout(()=>{
  this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
},4000)
    }else{
      this.setState({addCaseDocsError:"Please select the Document file First"})
    }
  }else{
    this.setState({addCaseDocsError:"Please enter the document name"})
  }
  }
  render() {
    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "600px" }}
          >
            <div className="case-overview-items-area">
              <Tabs /* onChange={callback} */ type="card" defaultActiveKey="3">
                <TabPane tab="Client Details" key="1">
                  <Table celled fixed padded selectable style={{fontSize:'12px',fontWeight:'550'}}>
                    <Table.Row>
                      <Table.Cell  verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
    Full Name :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.userProfile  && this.state.caseDetail.userProfile.firstName+" "+this.state.caseDetail.userProfile.lastName}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
    Emirates ID : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.emiratesId?this.state.caseDetail.emiratesId:this.state.caseDetail.emiratesIdNumber}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        Email :  <span style={{marginLeft:'30px'}}>{this.state.caseDetail.userProfile  && this.state.caseDetail.userProfile.email}</span>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                        Phone Number :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.userProfile  && this.state.caseDetail.userProfile.phoneNumber}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        Home Tel: <span style={{marginLeft:'30px'}}>{this.state.caseDetail.homeTel ? this.state.caseDetail.homeTel:'Not Provide'}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        Office Tel : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.officeTel ? this.state.caseDetail.officeTel:'Not Provide'}</span>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        Nationality :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.nationality }</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        UAE Address : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.userProfile  && this.state.caseDetail.userProfile.address}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                        Permanent Address : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.permanentAddress }</span>
                      </Table.Cell>
                    </Table.Row>
                  </Table>
                </TabPane>
                <TabPane tab="File Details" key="2">
                <Table celled fixed padded selectable style={{fontSize:'12px',fontWeight:'550'}}>
                <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       File No :<span style={{marginLeft:'30px'}}>BZ/{this.state.caseDetail.fileNo}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       Client Type :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.clientType}</span>
                      </Table.Cell>
                     
                    </Table.Row>
                    <Table.Row>
                    
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                       Lawyer : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.lawyerName?this.state.caseDetail.lawyerName:'Not Provide'}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                      </Table.Cell> 
                    </Table.Row>
                    </Table>
                </TabPane>
                <TabPane tab="Case Details" key="3">
                <Table celled fixed padded selectable style={{fontSize:'12px',fontWeight:'550'}}>
                <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       Case No :<span style={{marginLeft:'30px'}}>BZ/{this.state.caseDetail.caseNo}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                       Case Type : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.caseType}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                       Case Status : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.caseStatus}</span>
                      </Table.Cell>
                     
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       Client Type :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.clientType}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                       Lawyer : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.lawyerName?this.state.caseDetail.lawyerName:'Not Provide'}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                      Court: <span style={{marginLeft:'30px'}}>{this.state.caseDetail.stage}</span>
                      </Table.Cell>
                     
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       Opponent Name :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.oppenentName?this.state.caseDetail.oppenentName:'Not Provide'}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                       POA Type : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.poaType?this.state.caseDetail.poaType:'Not Provide'}</span>
                      </Table.Cell>
                      <Table.Cell verticalAlign="middle" style={{height:'60px'}} singleLine textAlign="left">
                      Accusation: <span style={{marginLeft:'30px'}}>{this.state.caseDetail.accusation?this.state.caseDetail.accusation:'Not Provide'}</span>
                      </Table.Cell>
                     
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell x verticalAlign="middle" style={{height:'60px'}}  singleLine textAlign="left">
                       Opening Date :<span style={{marginLeft:'30px'}}>{this.state.caseDetail.createdAt && this.state.caseDetail.createdAt.slice(0,10)}</span>
                      </Table.Cell>
                      <Table.Cell  colSpan="2" verticalAlign="middle" style={{height:'60px'}}  textAlign="left">
                       Case Detail : <span style={{marginLeft:'30px'}}>{this.state.caseDetail.caseDescription}</span>
                      </Table.Cell>
                    
                     
                    </Table.Row>
                    </Table>
                   
                   
                </TabPane>
                <TabPane tab="Case writing" key="6">
                <div className="case-writing-header">
                      <p>Case writing</p>
                      <p style={{fontSize:'30px',fontWeight:'700'}} className="arabic-font">كتابة الحالة</p>
                    </div>
                    <CaseWriting caseDetail={this.state.caseDetail} />
                </TabPane>  
                <TabPane tab="Add Audio" key="7">
                   <div className="case-add-audio-area">
                  <AddAudioCase  caseDetail={this.state.caseDetail} />
                   </div>
                </TabPane>
                <TabPane tab="Case Attachments" key="4">
                <Table celled fixed padded selectable sortable>
                  <Table.Header style={{ fontSize: "12px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "Status"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "Status",
                          })
                        }
                        textAlign="left"
                        singleLine
                      >
                        Serial No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "CaseNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "CaseNo",
                          })
                        }
                        singleLine
                        textAlign="left"
                      >
                        Document Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "FileNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "FileNo",
                          })
                        }
                        singleLine
                        textAlign="left"
                      >
                     Document No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                       
                        singleLine
                        textAlign="left"
                      >
                     Document File
                      </Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                {this.state.caseDetail.attachments && this.state.caseDetail.attachments.length>0?
                this.state.caseDetail.attachments.map((item,index)=>{
                  return  <Table.Row style={{fontSize:'12px'}}>
                  <Table.Cell>
                    {index+1}
                  </Table.Cell>
                  <Table.Cell>
                  {item.docName}
                    </Table.Cell>
                    <Table.Cell>
                    {item.docNumber?item.docNumber:'Not Exist'}
                    </Table.Cell>
                    <Table.Cell>
                    <div style={{color:"#353535",textDecoration:'underline',cursor:'pointer'}} onClick={()=>this.handleShowDoc(item)} >  show File</div>
                    </Table.Cell>
                </Table.Row>
                })
                :(
                  <Table.Row>
                    <Table.Cell
                      textAlign="center"
                      colSpan="4"
                      textAlign="center"
                    >
                      <center>
                        <div className="loader-for-tabel-invoce">
                          <div className="loader1188"></div>
                        </div>
                      </center>
                    </Table.Cell>
                  </Table.Row>
                )}
               
                </Table>
<center><Button onClick={this.handleShow5}><Icon name="add circle" />Add Documents</Button></center>
          <di className="additional-require-document-area">
           <p></p>
          </di>
                </TabPane>
                
                <TabPane tab=" Case Litigation" key="5">
                <p style={{fontSize:'22px',fontWeight:'550',marginBottom:'5px'}}>Case Invoices</p>
                <Table celled fixed padded selectable sortable>
                  <Table.Header style={{ fontSize: "12px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "Status"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "Status",
                          })
                        }
                        textAlign="left"
                        singleLine
                      >
                        Invoice No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "CaseNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "CaseNo",
                          })
                        }
                        singleLine
                        textAlign="left"
                      >
                        file No
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={
                          this.state.column === "FileNo"
                            ? this.state.direction
                            : null
                        }
                        onClick={() =>
                          this.handleSort({
                            type: "CHANGE_SORT",
                            column: "FileNo",
                          })
                        }
                        singleLine
                        textAlign="left"
                      >
                     Invoice Status
                      </Table.HeaderCell>
                      <Table.HeaderCell
                       
                        singleLine
                        textAlign="left"
                      >
                    Remaining Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell
                       
                       singleLine
                       textAlign="left"
                     >
                   Total Amount
                     </Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                {this.state.caseDetail.invoice ? this.state.caseDetail.invoice.length>0?
                this.state.caseDetail.invoice.map((item,index)=>{
                  return  <Table.Row style={{fontSize:'12px'}}>
                  <Table.Cell>
                    {item.invoiceNo}
                  </Table.Cell>
                  <Table.Cell>
                  {item.fileNo}
                    </Table.Cell>
                    <Table.Cell>
                    {item.status}
                    </Table.Cell>
                    <Table.Cell>
                    {item.pendingAmount}
                   </Table.Cell>

                   <Table.Cell>
                   {item.amountToBePaid}
                   </Table.Cell>
                </Table.Row>
                }):(
                  <Table.Row>
                    <Table.Cell colSpan="5" textAlign="center">
                      <center>
                        <div
                          className="loader-for-tabel-invoce"
                          style={{ fontWeight: "550" }}
                        >
                          No Inovice Exist Yet!
                        </div>
                      </center>
                    </Table.Cell>
                  </Table.Row>
                )
              
                :(
                  <Table.Row>
                    <Table.Cell
                      textAlign="center"
                      colSpan="4"
                      textAlign="center"
                    >
                      <center>
                        <div className="loader-for-tabel-invoce">
                          <div className="loader1188"></div>
                        </div>
                      </center>
                    </Table.Cell>
                  </Table.Row>
                )}
               
                </Table>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} size={this.state.size} centered  >
        
        <Modal.Body style={{padding:'0px'}}>
          {/* fa fa-exclamation-circle */}
          {this.state.veiwReceiptLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.veiwReceiptSuccess? <div  >
          
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.veiwReceiptError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
         <center>
          {/*   <img style={{width:'100%'}} onLoad={this.handleLoad} id="ReceiptImage11" src={this.state.DocsIMage} />
            */}    
         
                {
   this.state.DocsIMage.slice(this.state.DocsIMage.length-3)==="pdf"?<iframe  style={{border:'1px solid lightgrey',width:'100%',minHeight:this.state.height,msOverflowY:'auto'}} onLoad={this.handleLoad} src={this.state.DocsIMage.slice(0,5)==="https"? this.state.DocsIMage:`https://prod-legalemirates.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.DocsIMage}`}  ></iframe>
   :<img style={{border:'1px solid lightgrey',width:'100%'}} onLoad={this.handleLoad} src={this.state.DocsIMage.slice(0,5)==="https"? this.state.DocsIMage:`https://prod-legalemirates.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.DocsIMage}`} />

 }
          </center>
        </Modal.Body>
    
      </Modal> 
      <Modal show={this.state.show5} onHide={this.handleClose5} size="sm" centered  >
      {this.state.addCaseDocsSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Add Document
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.addCaseDocsSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="Document is Successfuly Uploaded"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose5} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Add the additional document here related to this case</p>
       <Form.Group controlId="formBasicEmail">
    <Form.Label>Document Name</Form.Label>
    <Form.Control ref={(input)=>this.documentName=input} type="text" placeholder="" />
    
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Document No</Form.Label>
    <Form.Control ref={(input)=>this.documentNo=input} type="text" placeholder="" />
  </Form.Group>
       <div className="upload-photo-area">
                     <div>
          <center>{this.state.preview.length>0? <embed width="120" height="60" name="plugin" src={this.state.preview[0]}  />:<Icon style={{marginTop:'30px'}} name="upload" size="huge" />}
                    
                     <div className="image-upload">
                                    <label for="file-input288888">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Document</p>
                                    </label>
                                 {/*    <br/>
                                    <div onClick={this.handlePhoto} style={{color:'#666666',fontWeight:'550'}}><Icon name="camera" /> Take Photo</div>
 */}
                                    <input id="file-input288888"  name="photo15" type="file" accept=".png,.jpg,.jpeg,.pdf"    onChange={this.handleFileChange} />
                                  </div>
                                  </center>
                    </div>    
                   
            </div>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag3? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Uploading Please wait....</p>
            </center>:''}
     {this.state.addCaseDocsError.length>0?<Alert message={this.state.addCaseDocsError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.addCaseDocsSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose5}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleAddDocuments} color='black'>Upload Document</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getCaseDetailReducer: state.CaseDetail1.getCaseDetailReducer,
    fileSearchReducer: state.CaseDetail1.fileSearchReducer,
    addDocumentREd: state.CaseDetail1.addDocumentREd,
    addAudioNoteREd:state.CaseDetail1.addAudioNoteREd
  };
};
export default compose(
  withReducer("CaseDetail1", reducer),
  connect(mapStateToProps),
  withRouter
)(CaseDetail);
