import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_LAW_DOCS_DETAIL = "GET_LAW_DOCS_DETAIL";
export const GET_LAW_DOCS_DETAIL_LOADING =
"GET_LAW_DOCS_DETAIL_LOADING";
export const GET_LAW_DOCS_DETAIL_LOADING_FAILED =
"GET_LAW_DOCS_DETAIL_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getDocsLawDetailLoading = ms => ({
    type: GET_LAW_DOCS_DETAIL_LOADING,
    payload: ms
    });
    
    export const getDocsLawDetailLoadingFailed = ms => ({
    type: GET_LAW_DOCS_DETAIL_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getDocsLawDetail(id) {
        const request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/legalPenalRoutes/api/v1/lawDocuments/getLawDocumentsWithPagination?documentId=${id}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.consultantToken}
              });
   
    
    return dispatch => {

    dispatch(getDocsLawDetailLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getDocsLawDetail")
    dispatch({
    type: GET_LAW_DOCS_DETAIL,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getDocsLawDetailLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getDocsLawDetailLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getDocsLawDetailLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getDocsLawDetailLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


