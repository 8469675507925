import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import { message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './formStyle.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import ReCAPTCHA from "react-google-recaptcha";
  import * as Actions from "./store/actions";
  import { compose } from "redux";
  import { connect } from "react-redux";
  import reducer from "./store/reducers";
  import withReducer from "../../store/withReducer";
  import store from "../../store";


  const recaptchaRef = React.createRef();
class FormAdminSignIn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        email: '',
        lat:'',
        long:'',
        barCode:'',
        password:'',
        error: {},
        type:'password',
        passFlag:false,
        captcha:null,
        buttonFlag:false,
        loaderFlag:false,
        errMessage:'',
        adminSignFlag:false,
        adminSignFlag1:false,
        signInRes:'',
        show:false,
        exp:'',
        errorMessage:true,
        suspious:false,
        suspiouMsg:'',
        codeAuth:'',
        eye:'fa-eye',
        type1111:'password'
    } 
    this.toastId=React.createRef(null);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
 
  }
  handleClose() {
    this.setState({ show: false });
  }
componentWillMount(){
  if(localStorage.consultantToken){
    this.props.history.push('./cases')
  }
}
  handleShow() {
    this.setState({ show: true });
  }
    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'email mast be A Valid Email';
                        break;
                    default:
                        err.message = 'email can not be empity';
                        break;
                }
            });
            return errors;
        }),
     
        password:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'please enter password ';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            email: this.state.email,
            password:this.state.password,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };
 
    submitHandler = event => {

        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
 
              this.setState({loaderFlag:true,adminSignFlag:true,exp:''})
             /*  alert("no") */
            let obj={
                email:this.state.email,
                password:this.state.password
            }
       this.props.dispatch(Actions.adminSignIn(obj))
            /* adminSignIn */
         
      
        }
    }
  /*   handleEmailSent=()=>{
    
       let obj={
    email: this.state.email,
    deviceName: this.state.platform,
    barCodeDigit: this.state.,
    lat: location.coords.latitude,
    lon: location.coords.longitude
   }

    } */
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }

    onChangeCaptha=(value)=>{
        if(value===null){
          this.setState({captcha:null,buttonFlag:true})
        }
        else{
          this.setState({captcha:value,buttonFlag:false})
        }
      }
      handleCodeAuthentication=(e)=>{
          e.preventDefault()
        if(this.state.codeAuth.length<6){
          this.setState({exp:'Please enter the valid Code'})
        }else{  
        var OSName = "Unknown OS";
        if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var platform = navigator.platform;
        var fullVersion = "" + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;
    
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
          browserName = "Opera";
          fullVersion = nAgt.substring(verOffset + 6);
          if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
          browserName = "Microsoft Internet Explorer";
          fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
          browserName = "Chrome";
          fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
          browserName = "Safari";
          fullVersion = nAgt.substring(verOffset + 7);
          if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
          browserName = "Firefox";
          fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if (
          (nameOffset = nAgt.lastIndexOf(" ") + 1) <
          (verOffset = nAgt.lastIndexOf("/"))
        ) {
          browserName = nAgt.substring(nameOffset, verOffset);
          fullVersion = nAgt.substring(verOffset + 1);
          if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
          }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
          fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
          fullVersion = fullVersion.substring(0, ix);
    
        majorVersion = parseInt("" + fullVersion, 10);
        if (isNaN(majorVersion)) {
          fullVersion = "" + parseFloat(navigator.appVersion);
          majorVersion = parseInt(navigator.appVersion, 10);
        }
  
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(location => {
           let obj={
        email: this.state.signInRes.email,
        deviceName: platform,
        barCodeDigit: this.state.codeAuth,
        lat: location.coords.latitude,
        lon: location.coords.longitude
       }

       /* ,lat:location.coords.latitude,long:location.coords.longitude,barCode:this.codeAuth.value */
      this.setState({adminSignFlag1:true,errorMessage:true})
         /* codeVerifuAuth */
         this.props.dispatch(Actions.codeVerifuAuth(obj))


            }, err => err.code == 1 ?     (! toast.isActive(this.toastId.current)) ?
      this.toastId.current =toast.error('Please Turn On the Location First', {
      position: "top-right",
      toastId:'logined',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      })
    :'' :'' )
          } else {
          alert("Geolocation is not supported by this browser.")
      
          }
  
        }  
/* codeVerifyAdminReducer */

      }
      componentWillReceiveProps(nextProps){
        let resNumber1 =nextProps.AdminSignInReducer;
 
        
        if (!resNumber1.isLoading) {
          if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
              if(this.state.adminSignFlag){

                if(localStorage.loginFlagConsultant==='login'){
                    this.setState({adminSignFlag1:false,codeAuth:'',show:false,loaderFlag:false})
                    this.props.history.push('/cases')
                    window.scrollTo({top:0, behavior: 'smooth'})
                    message.success(`You Have Successfully Login As Consultant`);
     
              }    
                /* this.setState({signInRes:resNumber1.data,loaderFlag:false,adminSignFlag:false,show:true}) */
               
              }
          } else {
          this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
          }
        }
        let resNumber2 = nextProps.codeVerifyAdminReducer;
        if (!resNumber2.isLoading) {
          if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
         /*    if(localStorage.loginFlagConsultant==='login'){
              if(this.state.adminSignFlag1){
                this.setState({adminSignFlag1:false,codeAuth:'',show:false,loaderFlag:false})
                this.props.history.push('/cases')
                window.scrollTo({top:0, behavior: 'smooth'})
                message.success(`You Have Successfully Login As Consultant`);
 
          } 
              } */
          } else {
            if(this.state.errorMessage){
            if(! toast.isActive(this.toastId.current)) {
                this.toastId.current =toast.error(resNumber2.errMsg, {
                position: "top-right",
                toastId:'logined',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                })
            }
            if(resNumber2.errMsg.message==="Topt expire Or invalid"){
                this.setState({exp:"Topt expire Or invalid",codeAuth:'',errorMessage:false,loaderFlag:false,errFlag:true})

            }else if(resNumber2.errMsg.codeName==="EmailSendedOnSuspicious"){
              this.setState({codeAuth:'',exp:"Code sent to Admin Please Contact Admin and Try Again",suspious:true,suspiouMsg:'You have been prevented as an unusual sign in attempt at Legal Emirates Admin Panel from different location. Please contact your admin to verify yourself through a verification code and confirm your login attempt.',errorMessage:false,loaderFlag:false,errFlag:true})
            }else if(typeof resNumber2.errMsg== 'string' ){
          this.setState({codeAuth:'',errFlag:true,exp:resNumber2.errMsg,errorMessage:false,loaderFlag:false})
            }else{
              this.setState({codeAuth:'',errFlag:true,exp:resNumber2.errMsg.message,errorMessage:false,loaderFlag:false})
        
            }  
        }

        }
        }
      }
      handleShowPassword=()=>{
        if(this.state.type1111==='password'){
         this.setState({type1111:'text',eye:'fa fa-eye-slash'})
        }else{
          this.setState({type1111:'password',eye:'fa fa-eye'})
        }
      }
    render() {

     /*   console.log(this.state.exp,"this.state.exp") */
        return (
            <form onSubmit={this.submitHandler} className='contactForm'>
                <div className="row">
                    
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Username/Email*</p>  
                        <div className="formInput form-control field-design">
                         
                            <img src={require('../../images/signIn/03-01.png')} />
                            <input
                             autocomplete="off"
                                placeholder=""
                                value={this.state.email}
                                name="email"
                                onChange={this.changeHandler}
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type="email" />

                            {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px',color:'wheat'}}>{this.state.error.email}</p>}
                        </div>
                    </div>
                  
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                             autocomplete="off"
                               
                                value={this.state.password}
                                name="password"
                                onChange={this.changeHandler}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type} />
                             {this.state.passFlag ?   <img className="password-image2" onClick={this.handleShowPass} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                           <div style={{marginTop:'8px'}} className={this.state.error.password ?"password-area" :"password-area1" }>
                            {this.state.error.password && <p style={{fontWeight:'550',color:'wheat'}}>{this.state.error.password}</p>}
                           {/*  <p style={{fontWeight:'550'}}> <NavLink style={{color:'wheat'}} exact to='/Adminpassreset'>  Forgot Password?</NavLink></p> */}
                                </div>    
                      {/* forgotpassword */}
                        </div>
                     </div>   
                     <div className="col-12" style={{marginTop:'10px',marginBottom:'20px'}}>
                      <p style={{color:'white'}}>{this.state.errMessage}</p>   
                    <center>
                    <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                    </center>
                     </div>
                   
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="button-loaader-area-cutomer-signup" style={{marginTop:'5px'}}>
                      {/*   <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/termsconditions'>  Terms & Conditions</NavLink></p>    
                       */}  <button  disabled={this.state.buttonFlag} style={{cursor:'pointer',marginTop:'5px',borderRadius:'12px',width:'70%'}} type="submit">Login </button>{this.state.loaderFlag?<div style={{marginLeft:'10px'}} className="loader"></div> :""}
                       </div>
                       
                    </div>
                </div>
                <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
          <Modal.Body>
            <form >              <div className="admin-otp-verification">
               <center>
               {this.state.suspious ?    <p style={{textTransform:'capitalize'}}>LOCATION VERIFICATION</p> :   <p style={{textTransform:'capitalize'}}>AUTHENTICATION VERIFICATION</p>}
            
                      {this.state.suspious ? <p style={{color:'grey',fontSize:'13px'}}>{this.state.suspiouMsg}</p>: <p style={{color:'lightgrey',fontSize:'13px'}}>{ !this.state.signInRes.qrcodePic && "Check Your Authentication Application for Code"}</p> }
                  
                 
                   <img style={{width:'180px'}} src={this.state.signInRes.qrcodePic && this.state.signInRes.qrcodePic} />
                 
                   <p>Enter 6-digit Authentication Code </p>
                   <input type={this.state.type1111} required value={this.state.codeAuth} onChange={(e)=>this.setState({codeAuth:e.target.value})}  placeholder="XXXXXX"  pattern=".{0}|.{6,}"  className="otp-code-legaldfd" maxLength='6' /><span style={{marginLeft:'-30px',cursor:'pointer'}}><i onClick={this.handleShowPassword} className={`fa ${this.state.eye}`}></i></span>
                    <p style={{color:'red',fontSize:'14px'}}>{this.state.exp}</p>
               </center>
               <div className="admin-otp-verification-button">
                   <p onClick={this.handleClose}>Back</p>
                   <button className="buttonAddminConitue" onClick={this.handleCodeAuthentication}><p style={{marginLeft:'10px'}}>Continue</p></button>
               </div>
              </div>
              </form>

          </Modal.Body>
      
        </Modal>
            </form>
        )
    }
}
  const mapStateToProps=(state)=> {
    return {
        AdminSignInReducer: state.receptionistSignIn.AdminSignInReducer,
        codeVerifyAdminReducer:state.receptionistSignIn.codeVerifyAdminReducer
    };
  } 
export default compose(
  withReducer("receptionistSignIn", reducer),
  connect(mapStateToProps),withRouter
)(FormAdminSignIn); 