import React, { useEffect, useState, Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import "../sideBar/style.scss";
import "../sideBar/style.css";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import {
  Form,
  ButtonToolbar,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
} from "react-bootstrap";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from "antd";
import "antd/dist/antd.css";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Rating, Icon } from "semantic-ui-react";
import { Grid, Image, Label, Segment } from 'semantic-ui-react'
var moment = require("moment");
class AllLawDocs extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      limit: 10,
      pageNo: 1,
      userId: undefined,
      totalPages: 11,
      allLawDocsData: [],
      pageNumber: 1,
      options: [],
      direction: null,
      column: null,
      stats:{}
    };
  }
  componentWillMount() {
    let obj = {
      userId: this.state.userId,
      limit: this.state.limit,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllLawDocs(obj));
    /* getAllLawDocsCounter */
  }
  handleLimit = (e) => {
    this.setState({ limit: e.target.value, allLawDocsData: [] });
    let obj = {
      userId: this.state.userId,
      limit: e.target.value,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllLawDocs(obj));
  };
  handleJumpPage = (e) => {
    this.setState({ pageNumber: e.target.value, allLawDocsData: [] });
    let obj = {
      userId: this.state.userId,
      limit: this.state.limit,
      pageNo: e.target.value,
    };
    this.props.dispatch(Actions.getAllLawDocs(obj));
  };
  handlePageInc = () => {
    if (this.state.totalPages > this.state.pageNumber ) {
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber + 1,
      }));
      this.setState({ allLawDocsData: [] });
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNumber + 1,
      };
      this.props.dispatch(Actions.getAllLawDocs(obj));
    }
  };
  handlePageDec = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ allLawDocsData: [] });
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber - 1,
      }));
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNumber - 1,
      };
      this.props.dispatch(Actions.getAllLawDocs(obj));
    }
  };
  /* AllLawDocsRed */
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.AllLawDocsRed;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
        console.log(resNumber1.data,"datafffffffff")
        if (resNumber1.data.lawDocuments.docs.length > 0) {
          this.setState({
            allLawDocsData: resNumber1.data.lawDocuments.docs,
            totalPages: resNumber1.data.lawDocuments.totalPages,
          });
        } else {
          this.setState({ allLawDocsData: ["nothing"] });
        }
      } else {
        this.setState({
          loaderFlag: false,
          errFlag: true,
          errMessage: resNumber1.errMsg,
        });
      }
    }
  /*   let resNumber2 = nextProps.fileSearchReducer;

    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data === undefined)) {
        if (resNumber2.data.users.length > 0) {
          resNumber2.data.users.map((item) => {
            let obj = {
              value: `${item.firstName} ${item.lastName} (${item.phoneNumber})`,
              data: item,
            };
            this.state.options.push(obj);
          });
          this.setState({ falged: true });
        }
      } else {
        if (resNumber2.errMsg === "This token is expire! Please log in again") {
          this.props.dispatch(Actions.logoutAdmin());
        }
      }
    } */
  
    /* allCasesCounterReducer */
  }
  /* fileSearchReducer */
  handleSearch = (e) => {
    if (e.length > 0) {
      let uniq = {};
      let arrFiltered = this.state.options.filter(
        (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
      );
      this.setState({ options: arrFiltered });
      /* this.props.dispatch(Actions.getSeachedFiles(e)); */
    } else {
      this.setState({ allLawDocsData: [] });
      let obj = {
        userId: this.state.userId,
        limit: this.state.limit,
        pageNo: this.state.pageNo,
      };
      this.props.dispatch(Actions.getAllLawDocs(obj));
    }
  };
  handleSelect = (e, value) => {
    let uniq = {};
    let arrFiltered = this.state.options.filter(
      (obj) => !uniq[obj.value] && (uniq[obj.value] = true)
    );
    this.setState({ options: arrFiltered, allLawDocsData: [] });
    let obj = {
      userId: value.data._id,
      limit: this.state.limit,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllLawDocs(obj));
  };
  handleSort = (action) => {
    switch (action.type) {
      case "CHANGE_SORT":
        this.setState({ column: action.column });
        this.setState((state) => ({
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        }));
        this.setState((state) => ({
          allLawDocsData: state.allLawDocsData.reverse(),
        }));
      default:
    }
  };
  handleCaseDetail=(item)=>{
 localStorage.setItem('caseId',item._id)   
this.props.history.push('/caseDetail')
  }
  handleDocDetail=(id)=>{
  localStorage.setItem("lawDocumentId",id)
  this.props.history.push('/lawDetail')  
  }
  handleSearchFun=(e)=>{
    let obj = {
      userId: 'fffffff',
      name:e.target.value,
      limit: this.state.limit,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getAllLawDocs(obj));
  }
  handleUpdateDocs=(id)=>{
    localStorage.setItem('updateLawId',id)
    this.props.history.push('/updateLawDocs')
  }
  /* updateLawDocs */
  render() {
    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "200px" }}
          >
      
            <div className="admin-cutomer-REcepnest-files-rea">
              <div className="receipnest-file-customer-files-area-top-heading">
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "550",
                    marginTop: "25px",
                  }}
                >
                  {" "}
                  All Law Documents 
                </p>
              </div>
              <div className="admin-cutomer-profile-area-search">
                <div className="search-area-admin-top">
                  <i className="fa fa-search"></i> |{" "}
                  {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */}{" "}
                  <input
                    className="search_input_width_file_rep1545"
                    type="text"
                    placeholder="Search with Document Name"
                   onChange={this.handleSearchFun}
                  />
                </div>
              </div>
            </div>
           <div className="all-law-document-area">
           <div className="container">
           <div className="row">
           {this.state.allLawDocsData.length > 0 ? (
                      this.state.allLawDocsData[0] != "nothing" ? (
                        this.state.allLawDocsData.map((item) => {
                          return <div className="col-12 col-md-4" style={{height:'100%',marginTop:'30px'}}>
             <Segment raised>
        <Label as='a' color='teal' onClick={()=>this.handleUpdateDocs(item._id)} ribbon>
        <Icon name="edit outline"/> Edit 
        </Label>
                        <span style={{fontSize:'18px',fontWeight:'600'}}>  {item.name}</span>

        <Segment padded>
       
          <Label attached='bottom'>  {moment(item.createdAt).format('MMMM Do YYYY,h:mm:ss a')}</Label>
          <div style={{fontSize:'12px',fontWeight:'700'}}>

          {item.description}
        </div>
        </Segment>
        <Label as='a' ribbon='right' color='grey' onClick={()=>this.handleDocDetail(item._id)} >
        <Icon name="bookmark outline"/>  Click here to full Read
        </Label>
      </Segment>
             </div>


})
) : (
  <div className="col-12" style={{height:'100%',marginTop:'30px'}} >
    <center>
                              <div
                                className="loader-for-tabel-invoce"
                                style={{ fontWeight: "550" }}
                              >
                                No Law Docs Exist  Yet!
                              </div>
                            </center>
    </div>
   )
   ) : (
    <div className="col-12" style={{height:'100%',marginTop:'30px'}} >
     <center>
                            <div className="loader-for-tabel-invoce">
                              <div className="loader1188"></div>
                            </div>
                          </center>
    </div>
     )}
            
            
           
           </div>
           <div className="quotaion-table-pagination-area">
              <div style={{ marginTop: "10px" }}>
                show{" "}
                <select onChange={this.handleLimit}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>{" "}
                per page
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ marginRight: "15px" }}>
                  Page {this.state.pageNumber} of {this.state.totalPages}{" "}
                  <span className="pagination-table-quotaion-dec">
                    <i
                      title="Previous Page"
                      onClick={this.handlePageDec}
                      className="fa fa-caret-left"
                    ></i>
                  </span>
                  <span className="pagination-table-quotaion-inc">
                    <i
                      onClick={this.handlePageInc}
                      title="Next Page"
                      className="fa fa-caret-right"
                    ></i>
                  </span>
                </span>
                jump to page{" "}
                <select onChange={this.handleJumpPage}>
                  {Array.from(
                    { length: this.state.totalPages },
                    (x, i) => i
                  ).map((item, index) => {
                    return <option value={index + 1}>{index + 1}</option>;
                  })}
                </select>
              </div>
            </div>
           </div>
           </div>
            
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    AllLawDocsRed: state.AllLawDocs1.AllLawDocsRed,
  };
};
export default compose(
  withReducer("AllLawDocs1", reducer),
  connect(mapStateToProps),
  withRouter
)(AllLawDocs);
