import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import {logoutAdmin,
  setCurrentAdmin} from '../../components/adminSignIn/store/actions/codeVerifyAuth'
  import setAuthToken from '../../components/adminSignIn/setAuthToken';
  import jwt_decode from 'jwt-decode';
  import AdminSignIn from '../Pages/adminSignIn'
  import AdminPassReset from '../Pages/adminPasswordReset'
  import AllCasesAreaPage from '../Pages/allCasesPage'
  import AddLawDocsPage from '../Pages/addLawDocsPage'
   import AllCasesDetailPage from '../Pages/caseDetailPage'
   import AllLawDocsAreaPage from '../Pages/allLawDocsPage'
   import UpdateLawDocsPage from '../Pages/updateLawDocPage'
   import DocsDestailAreaPage from '../Pages/LawDocsDetail'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './App.css';
import history from '../../history';
import store from '../../store';
/*    <HeaderTop className="headerTop" /> */
class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
          authFlag:false,
          adminAuth:false
    } 
   
    store.subscribe(() => {
   

      /* AdminAuthRiderReducer */
      
      let resNumber2 = store.getState().App1.AdminAuthRiderReducer;
      if(resNumber2.isAuthenticated){
          this.setState({adminAuth:true})
    
  }else{
  
    this.setState({adminAuth:false})
  }
    });
    
    
  }

   componentDidMount(){
   

/* logoutAdmin,
setCurrentAdmin
 */
    if(localStorage.consultantToken) {
   
      const decoded1 = jwt_decode(localStorage.consultantToken);
   
    
    
      const currentTime = Date.now() / 1000;
      if(decoded1.exp < currentTime) {
        store.dispatch(logoutAdmin());
        window.location.reload('/login');
      }else{
        setAuthToken(localStorage.consultantToken);
        store.dispatch(setCurrentAdmin(localStorage.getItem('receptionist')));
      }
    }

  } 
    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter history={history}>
                
                    <ToastContainer autoClose={2500} position="top-center" />
                  
                    <Switch>
                      {/* AdmineReceipnest UpdateLawDocsPage   */}
                      <Route exact path="/login" component={AdminSignIn} />
                      {this.state.adminAuth || localStorage.consultantToken ? 
                      <>
                      <Route exact path="/" component={AllCasesAreaPage} />
                      <Route exact path="/cases" component={AllCasesAreaPage} />
                      <Route exact path="/caseDetail" component={AllCasesDetailPage} />
                      <Route exact path="/allLawDocuments" component={AllLawDocsAreaPage} />
                      <Route exact path="/addLawDocs" component={AddLawDocsPage} />
                      <Route exact path="/updateLawDocs" component={UpdateLawDocsPage} />
                      <Route exact path="/lawDetail" component={DocsDestailAreaPage} />
                      </>
                      :null}
                     
                      <Route exact path="/Adminpassreset" component={AdminPassReset} />
                     
                      <Route exact path="/login" component={AdminSignIn} />
                      <Redirect to="/login" />
                      </Switch>
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default compose(
  withReducer("App1", reducer),
  connect()
)(App);   
 {/* <Route exact path="/founder" component={ComingSoon} /> */}