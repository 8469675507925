import _ from 'lodash'
import React, { Component } from 'react'
import { Search, Grid, Header, Segment ,Label,List} from 'semantic-ui-react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import { compose } from "redux";

const initialState = { isLoading: false, results: [], value: '' }

const source =[
  
  {
    title: "Cases",
    rout:'/cases' 
  },
  {
    title: "All Cases",
    rout:'/cases' 
  },
  {
    title: "Assigned Cases",
    rout:'/cases' 
  }]
  /* /sessions */
  const resultRenderer = ({ title }) => <List>
  <List.Item>
    <List.Icon name='share' />
<List.Content>{title}</List.Content>
  </List.Item>
  <List.Item>
    </List.Item>
    </List>
 class HeaderSearch extends Component {
  state = initialState

  handleResultSelect = (e, { result }) =>{ this.setState({ value: result.title })
  /* console.log(result,"jjjjjjjjj") */
  this.props.history.push(result.rout)
}

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })
   

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.title)

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch),
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <Grid>
        <Grid.Column width={6}>
          <Search
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            resultRenderer={resultRenderer}
            value={value}
            {...this.props}
          />
        </Grid.Column>
       
      </Grid>
    )
  }
}
export default compose(
 withRouter
)(HeaderSearch); 